let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  BACKEND_SERVER = "http://localhost:5000/";
}

let WebRTC_SERVER = null;
if (process.env.REACT_APP_WebRTC_SERVER) {
  WebRTC_SERVER = process.env.REACT_APP_WebRTC_SERVER;
} else {
  WebRTC_SERVER = "http://localhost:1984";
}

const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/berry-material-react/react/default'
  basename: "",
  defaultPath: "/dashboard",
  API_SERVER: BACKEND_SERVER,
  WebRTC_SERVER: WebRTC_SERVER,
};

export default config;

